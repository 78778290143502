import { store } from 'react-notifications-component';

export const useAddNotification = (message: string, title: string, type: string) => {

    store.addNotification({
        title: title,
        message: typeof message === "string" ? message : "Unknown issue",
        type: type,
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true,
        },
    });

}