import State from './state';
import Reducer from './reducer'
import { createSlice } from '@reduxjs/toolkit'
import { BookingState } from './props';

const slice = createSlice({
    name: 'BookingState',
    initialState: State as BookingState,
    reducers: Reducer,
})

export const BookingSliceAction = slice.actions

export default slice.reducer
