import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import Locale from 'translations';
import { Modal, ModalBody } from 'reactstrap'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/configureStore';
import { useAddNotification } from 'helpers/addNotification/addNotifications';

interface PaymentURLModalProps {
    isPaymentOpened: boolean
    setIsPaymentOpened: Dispatch<SetStateAction<boolean>>
    paymentUrl: string
}

const PaymentURLModal = (props: PaymentURLModalProps) => {
    const history = useHistory()
    const { StadiumPage } = Locale

    const bookingAPIData = useSelector((state: RootState) => state.BookingState.bookingAPIdata);
    const selectedStadium = useSelector((state: RootState) => state.HeaderState?.viewedStadium);
    const togglePaymentURLModal = () => {
        props?.setIsPaymentOpened(!props?.isPaymentOpened)
    }

    const iframeRef = useRef<HTMLIFrameElement>(null)

    // This hook is listening an event that came from the Iframe
    useEffect(() => {
        const handleEvent = (event) => {
            const { message, data, origin } = event;
            console.log({ event })
            const paymentId = event?.data?.orderId
            if (event?.data?.type == "success" && paymentId == bookingAPIData?.booking_id) {
                togglePaymentURLModal()
                history?.push(`/success/${paymentId}`)
            }
            else if (event?.data?.type == "fail") {
                useAddNotification(StadiumPage?.failurePayMessage, "Payment Fail", "danger")
                togglePaymentURLModal()
                history?.push(`/${selectedStadium?.slug}`)
            }
        };
        window.addEventListener('message', handleEvent);
        return function cleanup() {
            window.parent.removeEventListener('message', handleEvent);
        };
    });

    return (
        <>
            <Modal
                isOpen={props?.isPaymentOpened}
                toggle={togglePaymentURLModal}
                size="xl"
                className="imagesModal">
                <ModalBody className='p-4'>
                    <iframe id="iframe" className='w-100' src={props?.paymentUrl} height="650" ref={iframeRef}></iframe>
                </ModalBody>
            </Modal>
        </>
    )
}

export default PaymentURLModal