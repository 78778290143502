import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';

const PaymentFailed = () => {
    const params = useParams()

    useEffect(() => {

        window.parent.postMessage({
            type: "fail",
            orderId: params?.id
        }, "*")
    }, [])
    return (
        <></>
    )
}

export default PaymentFailed