import { MalaebState } from './props';

export const currentLocale = window.localStorage?.getItem("currentLocale");
export default {
  currentLocale: "en",
  countries: undefined,
  currentCountry: undefined,
  latitude: undefined,
  longitude: undefined,
  isRTL: false,
  stadiumFilter: {},
  viewedStadium: {},
  sportsType: undefined,


} as MalaebState