import { BookingDetails, BookingState, Pitch, Slot } from './props';


const setBookingDetails = (
    state: BookingState,
    payload: { payload: BookingDetails },
) => {
    state.bookingDetails =
        payload.payload
}

const setBookingAPIData = (
    state: BookingState,
    payload: { payload: BookingDetails },
) => {
    state.bookingAPIdata =
        payload.payload
}

const setSelectedSlot = (
    state: BookingState,
    payload: { payload: Slot },
) => {
    state.selectedInfo.selectedSlot =
        payload.payload
}

const setSelectedPitch = (
    state: BookingState,
    payload: { payload: Pitch },
) => {
    state.selectedInfo.selectedPitch =
        payload.payload
}

const setIsPaymentOpened = (
    state: BookingState,
    payload: { payload: boolean },
) => {
    state.isPaymentOpened =
        payload.payload
}

export default {

    setBookingDetails,
    setSelectedSlot,
    setSelectedPitch,
    setBookingAPIData,
    setIsPaymentOpened
}
