import State from './state';
import Reducer from './reducer'
import { createSlice } from '@reduxjs/toolkit'
import { MalaebState } from './props';

const slice = createSlice({
  name: 'HeaderState',
  initialState: State as MalaebState,
  reducers: Reducer,
})

export const NewSliceActions = slice.actions

export default slice.reducer
