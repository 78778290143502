import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import counterReducer from "./CounterSlice/counter";
import userReducer from "./UserSlice/user";
import malaebReducer from "./MalaebSlice/malaeb";
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import HeaderState from "./MalaebState"
import SliderState from "./SliderSlice"
import BookingState from "./BookingSlice"

import { api } from '../services/api';
import { BookingSliceAction } from './BookingSlice/index';

const reducers = combineReducers({
	api: api.reducer,
	counter: counterReducer, //counterslice
	user: userReducer, //userSlice
	malaeb: malaebReducer,//malaebSlice
	HeaderState, //newSlice
	SliderState,
	BookingState
});

const persistConfig = {
	key: 'root56',
	storage,
	//   whitelist: ['user']
}
const persistedReducer = persistReducer(persistConfig, reducers)


const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware => {
		const middlewares = getDefaultMiddleware({
			serializableCheck: false,
		}).concat(api.middleware)

		return middlewares
	},
})

const persistor = persistStore(store)

setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ReturnType<typeof store.dispatch>
export { store, persistor }
