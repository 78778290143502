import State from './state';
import Reducer from './reducer'
import { createSlice } from '@reduxjs/toolkit'
import { SliderState } from './props';

const slice = createSlice({
    name: 'SliderState',
    initialState: State as SliderState,
    reducers: Reducer,
})

export const SliderSliceActions = slice.actions

export default slice.reducer
