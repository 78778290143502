import { SportType } from 'services/getTypesOfSports/getTypesOfSports';
import {  Country, Filter, MalaebState, Stadium } from './props';


const setLocale = (
    state: MalaebState,
    payload: { payload: string },
) => {
    state.currentLocale =
        payload.payload
}
const setIsRTL = (
    state: MalaebState,
    payload: { payload: boolean },
) => {
    state.isRTL =
        payload.payload
}

const setCountries = (
    state: MalaebState,
    payload: { payload: Country[] },
) => {
    state.countries =
        payload.payload
}

const setCurrentCountry = (
    state: MalaebState,
    payload: { payload: Country | undefined },
) => {
    state.currentCountry =
        payload.payload
}
const setFilter = (
    state: MalaebState,
    payload: { payload: Filter },
) => {
    state.stadiumFilter =
        payload.payload
}

const setLatitude = (
    state: MalaebState,
    payload: { payload: number | undefined },
) => {
    state.latitude =
        payload.payload
}

const setLongitude = (
    state: MalaebState,
    payload: { payload: number | undefined },
) => {
    state.longitude =
        payload.payload
}

const setViewedStadium = (
    state: MalaebState,
    payload: { payload: Stadium },
) => {
    state.viewedStadium =
        payload.payload
}

const setSportsType = (
    state: MalaebState,
    payload: { payload: SportType[] },
) => {
    state.sportsType =
        payload.payload
}


export default {
    setLocale,
    setIsRTL,
    setCountries,
    setCurrentCountry,
    setLatitude,
    setLongitude,
    setFilter,
    setViewedStadium,
    setSportsType,


}
