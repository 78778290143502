import React from 'react';
import ReactDOM, { hydrate, render } from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import "jquery/dist/jquery.min.js"
import { store as reduxToolKitStore } from "./redux/configureStore"
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faStar, faEnvelope, faPhone, faPaperPlane, faCheckCircle, faBan, faExclamationTriangle
  , faClose
} from '@fortawesome/free-solid-svg-icons'
library.add(faStar, faEnvelope, faPhone, faPaperPlane, faCheckCircle, faBan, faExclamationTriangle
  , faClose)
Sentry.init({
  dsn: 'https://8717ded4650640838a6093b890f1778a@o321626.ingest.sentry.io/6010402',
  integrations: [new Integrations.BrowserTracing()],


  tracesSampleRate: 1.0,
});
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <ReactNotification />
      <HelmetProvider>
        <Provider store={reduxToolKitStore}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    rootElement
  );

} else {
  render(
    <React.StrictMode>
      <ReactNotification />
      <HelmetProvider>
        <Provider store={reduxToolKitStore}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
    rootElement
  );

}
reportWebVitals();
