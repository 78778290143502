export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";
export const EDIT_USER = "EDIT_USER";

export const getUser = () => ({
	type: GET_USER,
});
export const setUser = (user) => ({
	type: SET_USER,
	user,
});
export const editUser = (user) => ({
	type: EDIT_USER,
	user,
});

const initState = {
	user: undefined,
};

export default (state = initState, action) => {
	switch (action.type) {
		case SET_USER:
			const { user } = action;
			return { ...state, user };
		default:
			return state;
	}
};
