import React from 'react'

const Intercom = () => {

    window.intercomSettings = {
        app_id: 'xtwgqd0w',
        tags: {
          type: 'list',
          data: [
            {
              type: 'tag',
              id: '2',
              url: '/tags/2',
            },
            {
              type: 'tag',
              id: '4',
              url: '/tags/4',
            },
            {
              type: 'tag',
              id: '5',
              url: '/tags/5',
            },
          ],
          url: '/contacts/5ba682d23d7cf92bef87bfd4/tags',
          total_count: 3,
          has_more: false,
        },
      };
      (function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/xtwgqd0w';
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    
  return (
    <div></div>
  )
}

export default Intercom