import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import Locale from './translations';
import { Loader } from './helpers/helpers';
import AxiosConfiguration from './axiosConfiguration';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import { RootState } from './redux/configureStore';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import "bootstrap/dist/css/bootstrap.min.css";
import 'scss/style.scss';
import "bootstrap/dist/js/bootstrap.bundle";
import Intercom from 'components/Intercom';

function App() {
  const locale = useSelector((state: RootState) => state.HeaderState.currentLocale);
  const locationPath = window.location.pathname;

  if (locale) {
    Locale.setLanguage(locale);
    document
      .getElementsByTagName('html')[0]
      .setAttribute('lang', locale);

    document
      .getElementsByTagName('html')[0]
      .setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr');
  }

  AxiosConfiguration();
  return (
    <>
      {locationPath !== "/liveScoring" ?
        <GoogleReCaptchaProvider reCaptchaKey={"6Lfytg4mAAAAAFvGweh44xB8nl7JV-aTTbgQNBZr"}>
          <Intercom />
          <Router>
            <Suspense fallback={<Loader />}>{Routes}</Suspense>
          </Router>
        </GoogleReCaptchaProvider>
        :
        <>
          <Router>
            <Suspense fallback={<Loader />}>{Routes}</Suspense>
          </Router>
        </>
      }
    </>
  );
}

export default App;
