import { getStorage, setStorage, uuidv4 } from '../../helpers/helpers';

//action creators
const UPDATE_USER_DATA = 'update_user_data';
export const setUserData = (user_data) => ({
  type: UPDATE_USER_DATA,
  payload: user_data,
});

//state
const initState = {
  user_uuid: getStorage('user_uuid') || setStorage('user_uuid', uuidv4()),
  user_data: getStorage('user_data') || {},
};

//reducers
export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      // const user_data = {...action.payload.data, token: action.payload.token}
      const user_data = { ...action.payload };
      setStorage('user_data', user_data);
      state = { ...state, user_data };
      break;

    default:
      state = state;
  }
  return state;
};
