import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';

const PaymentSuccess = () => {
    const params = useParams()
    useEffect(() => {
        window.parent.postMessage({
            type: "success",
            orderId: params?.id
        }, "*")
    }, [])

    return (
        <div>

        </div>
    )
}

export default PaymentSuccess