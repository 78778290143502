import { Route, Switch } from 'react-router-dom';
import React, { lazy } from 'react';
import { SERVER_URL } from 'Constants';
import PaymentURLModal from 'pages/StadiumPage/components/BookingComponent/PaymentURLModal';
const Footer = lazy(() => import('pages/Footer/Footer'));
const Header = lazy(() => import('pages/Header/Header'));
const StadiumsList = lazy(() => import('./pages/StadiumsListPage/Stadium'));
const Home = lazy(() => import('pages/Home/Home'));
const StadiumPage = lazy(() => import('./pages/StadiumPage/StadiumPage'));
const Error404 = lazy(() => import('./components/Error404/Error404'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const RediretToTournament = lazy(() => import('./pages/TournamentRedirect/RediretToTournament'));
const Events = lazy(() => import('./pages/Events/Events'));
const SuccessPage = lazy(() => import('pages/StadiumPage/components/BookingComponent/SuccessPage'));
const CheckOutPage = lazy(() => import('./pages/StadiumPage/components/BookingComponent/CheckOutPage'));
const LiveScoring = lazy(() => import('./pages/LiveScoringBoard/LiveScoring'));
import { PaymentSuccess, PaymentFailed } from './pages'
const locationPath = window.location.pathname;


const Routes = (
  <>
    {locationPath !== "/liveScoring" ? <Header /> : null}
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/stadiums" component={StadiumsList} />
      <Route exact path="/about" component={AboutUs} />
      <Route exact path="/blog" component={StadiumPage} />
      <Route exact path="/success/:id" component={SuccessPage} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/tournament/:id" component={RediretToTournament} />
      <Route exact path="/checkoutbooking" component={CheckOutPage} />
      <Route exact path="/events" component={Events} />
      <Route exact path="/liveScoring" component={LiveScoring} />
      <Route exact path={`${SERVER_URL}/:id`} component={PaymentURLModal} />
      <Route exact path="/:id" component={StadiumPage} />
      <Route exact path="/payment-success/:id" component={PaymentSuccess} />
      <Route exact path="/payment-failed/:id" component={PaymentFailed} />

      <Route exact path="*" component={Error404} />
    </Switch>
    {locationPath !== "/liveScoring" ? <Footer /> : null}


  </>
);

export default Routes;
