import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { SERVER_URL } from 'Constants'


const baseQuery = fetchBaseQuery({
  baseUrl: `${SERVER_URL}/api`,
  prepareHeaders: (headers, { getState }: any) => {

    let currentLocale = localStorage.getItem('currentLocale') ?? 'en'
    let token = getState()?.malaeb?.user_data?.token
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    // Set language to expect localized content
    headers.set('X-Locale', currentLocale)

    headers.set('accept', 'application/json')
    return headers
  },
})
const baseQueryWithInterceptor: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  return result
}

export const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  endpoints: () => ({}),
})
