const INCREMENT = "increment";
const DECREMENT = "decrement";

export const increment = () => ({
	type: INCREMENT,
});
export const decrement = () => ({
	type: DECREMENT,
});

const initState = {
	count: 5,
};

export default (state = initState, action) => {
	switch (action.type) {
		case INCREMENT:
			return { ...state, count: state.count + 1 };
		case DECREMENT:
			return { ...state, count: state.count - 1 };
		default:
			return state;
	}
};
