import { Slider, SliderState } from "./props"

const setSliders = (
    state: SliderState,
    payload: { payload: Slider[] },
) => {
    state.sliders =
        payload.payload
}

export default {
    setSliders
}